






























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class AvailableDownloads extends Vue {
  @Prop() tabs;
  @Prop() value;
}
