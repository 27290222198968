








































import { Component, Vue, Prop } from 'vue-property-decorator';
import { mapActions, mapMutations } from 'vuex';

import { COLOR } from '@/utils/constants';

@Component({
  methods: {
    ...mapActions({
      fetchArchiveData: 'datenabo/fetchArchiveData',
    }),
    ...mapMutations({
      setDatenaboData: 'datenabo/setDatenaboData',
      clearDatenaboData: 'datenabo/setDatenaboData',
    }),
  },
})
export default class DatenaboTable extends Vue {
  @Prop() table;
  @Prop() isAuthorized;

  setDatenaboData!: Function;
  color = COLOR;
  fetchArchiveData!: Function;
  clearDatenaboData!: Function;

  handleChangeTab(year) {
    //this.clearDatenaboData();
    //this.fetchArchiveData(year);
    const payload = {
      date: '02.06.2019',
      pdf: 'pdfUrl',
      xls: 'xlsUrl',
      tabs: [],
      table: {
        tabs: ['2021', '2020', '2019'],
        headers: [
          { text: 'Datum', value: 'datum' },
          { text: 'PDF', value: 'pdf' },
          { text: 'XLS', value: 'xls' },
        ],
        rows: [
          { datum: '2019-12-01', pdf: 'pdfUrl', xls: 'xlsUrl' },
          { datum: '2019-12-02', pdf: 'pdfUrl', xls: 'xlsUrl' },
        ],
      },
    };
    this.setDatenaboData(payload);
  }
}
