






















import { Component, Vue } from 'vue-property-decorator';
import { mapActions, mapGetters, mapMutations } from 'vuex';

import { COLOR } from '@/utils/constants';
import Loader from '@/components/fmh_components/common/Loader/Loader.vue';
import Error from '@/components/fmh_components/common/Error/Error.vue';
import DatenaboTable from '@/views/Datenabo/DatenaboTable/DatenaboTable.vue';
import AvailableDownloads from '@/views/Datenabo/AvailableDownloads/AvailableDownloads.vue';

interface DataTab {
  value: String;
  text: String;
}
@Component({
  components: { AvailableDownloads, Loader, Error, DatenaboTable },
  computed: {
    ...mapGetters({
      isAuthorized: 'auth/isAuthorized',
      tabs: 'datenabo/getTabs',
      table: 'datenabo/getTable',
      isLoading: 'datenabo/getLoading',
      isError: 'datenabo/getError',
    }),
  },
  methods: {
    ...mapActions({
      fetchDatenaboData: 'datenabo/fetchDatenaboData',
    }),
    ...mapMutations({
      setDatenaboData: 'datenabo/setDatenaboData',
      loading: 'datenabo/loading',
    }),
  },
})
export default class Datenabo extends Vue {
  color = COLOR;
  isAuthorized!: Boolean;
  fetchDatenaboData!: () => Promise<Object>;
  loading!: Function;
  setDatenaboData!: Function;
  clearDatenaboData!: Function;
  tabs!: Array<DataTab>;
  tab = null;

  /*@Watch('isAuthorized') async fetchData(val: Boolean) {
    if (val) {
      this.loading(true);
      await this.fetchDatenaboData();
      this.loading(false);
    }
  }

  async created() {
    if (this.isAuthorized) {
      this.loading(true);
      await this.fetchDatenaboData();
      this.loading(false);
    }
  }*/
}
